import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="user-profile-toggle"
export default class extends Controller {
  static targets = [ 'menu' ]
  connect() {
    this.endered_menu = false;
    this.timer = null
    this.hover_timeout = 300
  }
  open(e) {
    e.preventDefault()
    this.menuTarget.classList.toggle('hidden')
  }

  closeMenu(target) {
    target.classList.add('hidden')
  }

  close(e) {
    this.startTimer(this.menuTarget)
  }

  entered() {
    this.stopTimer();
  }

  startTimer(target) {
    this.timer = setTimeout(() => {
      this.closeMenu(target)
    }, this.hover_timeout)
  }

  stopTimer() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }
}
