import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sidebar"
export default class extends Controller {
  static targets = [ 'mobile' ]
  toggle(e) {
    e.preventDefault()
    this.mobileTarget.classList.toggle('hidden')
  }
}
