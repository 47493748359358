import { Controller } from "@hotwired/stimulus"
let throttle = require('lodash/throttle');

// Connects to data-controller="search-selection"
export default class extends Controller {
  static targets = [ "list" ]
  initialize() {
    this.handleKeyPress = throttle(this.handleKeyPress, 100)
    this.toggleClass = 'bg-gray-100'
  }
  connect() {
    document.addEventListener('keydown', this.handleKeyPress)
  }

  disconnect() {
    document.removeEventListener('keydown', this.handleKeyPress)
  }

  moveSelector(direction){
    let current = this.listTarget.getElementsByClassName(this.toggleClass)[0]
    switch(direction) {
      case 'up':
        if (current.parentElement.firstElementChild == current) {
          break;
        }
        current.classList.toggle(this.toggleClass)
        current.previousElementSibling.classList.add(this.toggleClass)
        break;
      case 'down':
        if (current.parentElement.lastElementChild == current) {
          break;
        }
        current.classList.toggle(this.toggleClass)
        current.nextElementSibling.classList.toggle(this.toggleClass)
        break;
    }
  }

  navigateToPage() {
    let current = this.listTarget.getElementsByClassName(this.toggleClass)[0]
    current.getElementsByTagName('a')[0].click()
  }
 handleKeyPress = (e) => {
    switch (e.keyCode) {
      case 13:
        e.preventDefault()
        this.navigateToPage()
      case 38:
        e.preventDefault()
        this.moveSelector('up')
        break;
      case 40:
        e.preventDefault()
        this.moveSelector('down')
        break;
    }
  }
}
