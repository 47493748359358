import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="content-preview"
export default class extends Controller {
  connect() {
  }

  handleResults(e) {
    console.log(e)
  }
}
