// Connects to data-controller="turbo-form-submit-redirect"
/* turbo_form_submit_redirect_controller.js */
import { Controller } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"

export default class extends Controller {
 static values = { url: String }
  connect () {
    window.open(this.urlValue, '_blank')
  }}
