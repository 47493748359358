import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "toggleable", 'bgChange', 'slider', 'visible' ]

  showContent(e) {
    e.preventDefault()
    this.toggleableTarget.classList.toggle('hidden')

    // This sets the visability in a hidden form so it gets persisted
    this.visibleTarget.value = !this.toggleableTarget.classList.contains('hidden')
  }
  buttonBG(e) {
    e.preventDefault()
    this.bgChangeTarget.classList.toggle('bg-indigo-900')
    this.bgChangeTarget.classList.toggle('bg-gray-200')
  }
  toggleSlide(e) {
    e.preventDefault()
    this.sliderTarget.classList.toggle('translate-x-5')
    this.sliderTarget.classList.toggle('translate-x-0')
  }
}
