import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"
let throttle = require('lodash/throttle')

// Connects to data-controller="search-bar"
export default class extends Controller {
  static targets = [ 'search', 'form' ]
  initialize() {
    this.search = throttle(this.search, 300)  }
  connect() {
    this.debounce = null
  }

  search(e) {
    // skip keys that control the drop down navigation
    if (e.keyCode == 38 || e.keyCode == 40 || e.keyCode == 13) { return }
    e.preventDefault()
    if (this.debounce) {
      clearTimeout(this.debounce)
    }

    this.debounce = setTimeout(() => {
      this.sendSearch()
    }, 300)
  }

  sendSearch() {
    Rails.fire(this.formTarget, 'submit')
  }
}
