import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

// Connects to data-controller="selection-navigation"
export default class extends Controller {

  navigate(e) {
    e.preventDefault()
    let contentId = e.target.value
    window.location = `/content/${contentId}/edit`
  }
}
