import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

// Connects to data-controller="select-filter"
export default class extends Controller {
  static targets = ['form']

  selected(e) {
    e.preventDefault()
    let form = e.target.closest('form')
    Rails.fire(form, 'submit')
  }
}
